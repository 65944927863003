
export default {
  name: "SectionFeaturedVideo",
  props: {
    section: {
      type: Object, default() { },
      required: true,
    },
  },
  data() {
    return {
      showPoster: true,
      embed: "",
    };
  },
  methods: {
    hide() {
      this.showPoster = false;
      this.embed = this.section.button.url;
    },
  },
};
