import { render, staticRenderFns } from "./index.vue?vue&type=template&id=765ae405&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/usr/app/components/header/Header.vue').default,HeroIntro: require('/usr/app/components/hero/Intro.vue').default,HeroIntroAnimation: require('/usr/app/components/hero/IntroAnimation.vue').default,Hero: require('/usr/app/components/hero/Hero.vue').default,Ticker: require('/usr/app/components/ticker/Ticker.vue').default,SectionSliderAdvancedCard: require('/usr/app/components/section/SliderAdvancedCard.vue').default,SectionFeaturedVideo: require('/usr/app/components/section/FeaturedVideo.vue').default,SectionList: require('/usr/app/components/section/List.vue').default,SectionSliderFlatCard: require('/usr/app/components/section/SliderFlatCard.vue').default,BannerCTA: require('/usr/app/components/banner/CTA.vue').default,Footer: require('/usr/app/components/footer/Footer.vue').default})
