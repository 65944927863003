
export default {
  name: "Intro",
  props: {
    intro: {
      type: Object,
      default() { }
    }
  },
  data() {
    return {
      isEntrance: true,
      background: {
        url: "/images/intro-bg.png",
        alternativeText: "Alamet Holistic"
      }
    };
  },
  mounted() {
    this.introStorage();
  },
  methods: {
    enter() {
      this.isEntrance = false;
      this.$emit("update:parent", this.isEntrance);
      sessionStorage.setItem("ai", false);
      const body = document.querySelector("body");
      body.classList.remove("intro-not-entrance");
      setTimeout(() => {
        body.classList.remove("no-scroll");
      }, 2000);
    },
    introStorage() {
      try {
        // Storage
        const body = document.querySelector("body");
        const storage = sessionStorage.getItem("ai");
        switch (storage) {
          case null:
            this.isEntrance = true;
            body.classList.add("intro-not-entrance");
            body.classList.add("no-scroll");
            sessionStorage.setItem("ai", true);
            this.$emit("update:parent", this.isEntrance);
            break;
          case "true":
            this.isEntrance = true;
            body.classList.add("intro-not-entrance");
            body.classList.add("no-scroll");
            sessionStorage.setItem("ai", true);
            this.$emit("update:parent", this.isEntrance);
            break;
          case "false": this.isEntrance = false;
            body.classList.remove("intro-not-entrance");
            this.$emit("update:parent", this.isEntrance);
            break;
          default:
            this.isEntrance = true;
            body.classList.add("intro-not-entrance");
            body.classList.add("no-scroll");
            sessionStorage.setItem("ai", true);
            this.$emit("update:parent", this.isEntrance);
            break;
        }
      } catch (error) {
        console.error("Error in introStorage()", error);
      }
    }
  }
};
