
export default {
  name: "SectionList",
  props: {
    section: {
      type: Object,
      required: true,
    },
    array: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      displayingCount: 4,
    };
  },
  computed: {
    sortedArray() {
      return this.array.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    showMore() {
      this.displayingCount += 4;
    },
  },
};
