
export default {
  name: "IntroAnimation",
  data() {
    return {
      isEntrance: true
    };
  },
  mounted() {
    this.entrance();
  },
  methods: {
    entrance() {
      // Storage
      const body = document.querySelector("body");
      this.isEntrance = body.classList.contains("intro-not-entrance");
    }
  }
};
