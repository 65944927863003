
import seo from "~/mixins/seo.js";
import categories from "@/static/categories.json";

// Animation
import { gsap } from "gsap";
import ScrollToPlugin from "gsap/dist/ScrollToPlugin.js";
import ScrollTrigger from "gsap/dist/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export default {
  layout: "intro",
  mixins: [seo],
  data() {
    return {
      page: {},
      seo: {
        metaTitle: "",
        metaDescription: "",
        shareImage: {},
        keywords: "",
        canonicalURL: "",
        metaRobots: "",
      },
      components: [],
      categories: categories,
      displayingCount: 4,
      isEntrance: true,
      moviesListingSlug: "",
    };
  },
  async fetch() {
    try {
      const { data } = await this.$axios.get(`/home?_locale=en`);
      this.page = data;
      this.seo = data.SEO;
      this.components = this.page.details.filter((item) => item.__component);

      const moviesListingResponse = await this.$axios.get(
        `/movies-listing?_locale=en`
      );
      this.moviesListingSlug = moviesListingResponse.data.slug;
    } catch (error) {
      // Handle the error here
      console.error("An error occurred during fetch:", error);
      // Optionally, you can throw the error again to propagate it to the caller
      return;
    }
  },
  watch: {
    isEntrance() {
      this.checkEntrance();
    },
  },
  async mounted() {
    await this.checkEntrance();
  },
  methods: {
    // TODO: burada ilk önce button kontrol edilip daha sonra class alınmalı.
    async checkEntrance() {
      this.isEntrance = await document.querySelector("body");
      this.isEntrance =
        this.isEntrance.classList.contains("intro-not-entrance");
      await this.entrancedAnimation();
    },
    entrancedAnimation() {
      const ttb = document.querySelector(".ttb");
      const btt = document.querySelector(".btt");
      const zto = document.querySelector(".zto");
      if (zto) gsap.to(".zto", { autoAlpha: 1, duration: 0.3, delay: 0.5 });
      if (ttb)
        gsap.fromTo(
          ".ttb",
          { top: -60 },
          { top: 0, duration: 0.3, opacity: 1, delay: 1 }
        );
      if (btt)
        gsap.fromTo(
          ".btt",
          { top: 40 },
          { top: 0, duration: 0.3, opacity: 1, delay: 1 }
        );
    },
  },
};
