
import site_settings from "@/static/site-settings.json";

export default {
  name: "HeroPromo",
  props: {
    hero: { type: Object, default() { } },
  },
  data() {
    return {
      socialAccounts: site_settings.socialMediaAccounts,
      showModal: false,
      embed: "",
      cdnUrl: process.env.CDN_URL
    };
  },
  computed: {
    isImage() {
      const imageTypes = [
        "image/webp",
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/gif",
        "image/svg+xml",
        "image/bmp",
      ];
      return imageTypes.includes(this.hero.background.mime);
    },
    isVideo() {
      const videoTypes = [
        "video/webm",
        "video/flv",
        "video/avi",
        "video/mpeg",
        "video/quicktime",
        "video/mp4",
        "video/3gpp",
      ];
      return videoTypes.includes(this.hero.background.mime);
    },
  },
  methods: {
    hide() {
      this.showModal = true;
      this.embed = this.hero.button.url;
    },
  },
};
