export default {
  head() {
    const seo = this.seo;

    if (!seo || seo === null || seo === undefined) {
      return {
        title: 'Alamet Holistic',
        htmlAttrs: {
          lang: 'en'
        },
        meta: []
      }
    }

    const metaTitle = seo.metaTitle || 'Alamet Holistic';
    const metaDescription = seo.metaDescription || '';
    const shareImageUrl = seo.shareImage && seo.shareImage.url ? process.env.CDN_URL + seo.shareImage.url : './alamet-holistic_og-image.jpg';
    const canonicalURL = seo.canonicalURL || process.env.CDN_URL + this.$route.path;
    const keywords = seo.keywords || 'Alamet Holistic';
    const shareImageAlt = seo.shareImage && seo.shareImage.alternativeText ? seo.shareImage.alternativeText : '';

    return {
      title: metaTitle,
      htmlAttrs: {
        lang: 'en'
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: metaDescription
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: metaTitle
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: metaDescription
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:site_name',
          name: 'site_name',
          property: 'og:site_name',
          content: 'Alamet Holistic'
        },
        {
          hid: 'og:author',
          name: 'author',
          property: 'og:author',
          content: 'efabrika'
        },
        {
          hid: 'og:locale',
          property: 'og:locale',
          content: 'tr_TR'
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: shareImageUrl
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: canonicalURL
        },
        {
          hid: 'og:keywords',
          name: 'keywords',
          property: 'og:keywords',
          content: keywords
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          property: 'twitter:image',
          content: shareImageUrl
        },
        {
          hid: 'twitter:site',
          name: 'site',
          property: 'twitter:site',
          content: '@alametholistic'
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {          hid: 'twitter:creator',
        name: 'twitter:creator',
        property: 'twitter:creator',
        content: '@alametholistic'
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        property: 'twitter:title',
        content: metaTitle
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        property: 'twitter:description',
        content: metaDescription
      },
      {
        hid: 'twitter:image:alt',
        name: 'twitter:image:alt',
        property: 'twitter:image:alt',
        content: shareImageAlt
      }
    ]
  }
}
}